import React from 'react';
import Divider from "../elements/divider"
import Inner from "../elements/inner"
import Content from "../elements/content"
import SVG from "./svg"
import { UpDown, UpDownWide } from "../styles/animations"
import Rocket from "./rocket.svg"
import Alien from "./alien.svg"
import Code from "./code.svg"
// @ts-ignore
import Intro from "../sections/intro"
const Hero = ({ offset, factor = 1 }) => {
    return (
        <div>
        <Divider speed={0.2} offset={offset} factor={factor}>
          <UpDown>
            <SVG icon="star" hiddenMobile width={48} stroke color="icon_orange" left="10%" top="20%" />
            <SVG icon="hexa" width={48} stroke color="icon_red" left="6%" top="90%" />
            <img style={{ width:'200px', position:'absolute', left:'60%', top:'20%'}} src={Code} alt="Alien Logo" />
            <SVG icon="box" width={6} color="icon_darker" left="60%" top="15%" />
            <Divider
              speed={-0.7}
              offset={0.5}
              factor={factor}
            >
              <img style={{ width:'430px', position:'absolute', left:'60%', top:'20%'}} src={Alien} alt="Alien Logo" />
            </Divider>
          </UpDown>
          <UpDownWide>
            <SVG icon="arrowUp" hiddenMobile width={16} color="icon_blue" left="80%" top="10%" />
            <SVG icon="triangle" width={12} stroke color="icon_brightest" left="90%" top="50%" />
            <SVG icon="circle" width={16} color="icon_yellow" left="70%" top="90%" />
            <SVG icon="triangle" width={16} stroke color="icon_darkest" left="30%" top="65%" />
            <SVG icon="cross" width={16} stroke color="icon_pink" left="28%" top="15%" />
            <SVG icon="circle" width={6} color="icon_darkest" left="75%" top="10%" />
            <SVG icon="upDown" hiddenMobile width={8} color="icon_darkest" left="45%" top="10%" />
          </UpDownWide>
          <SVG icon="circle" hiddenMobile width={24} color="icon_yellow" left="4%" top="20%" />
          <SVG icon="circle" width={6} color="icon_darkest" left="4%" top="20%" />
          <SVG icon="upDown" width={8} color="icon_darkest" left="95%" top="90%" />
          <SVG icon="upDown" hiddenMobile width={24} color="icon_orange" left="40%" top="80%" />
          <SVG icon="triangle" width={8} stroke color="icon_orange" left="25%" top="5%" />
          <SVG icon="circle" width={64} color="icon_green" left="90%" top="-15%" />
          {/* <SVG icon="box" hiddenMobile width={64} color="icon_purple" left="5%" top="90%" /> */}
          <SVG icon="box" width={6} color="icon_darkest" left="10%" top="10%" />
          <SVG icon="hexa" width={16} stroke color="icon_orange" left="10%" top="50%" />
          <SVG icon="hexa" width={8} stroke color="icon_orange" left="80%" top="70%" />
        </Divider>

        <Content sx={{ variant: `texts.bigger` }} speed={0.4} offset={offset} factor={1}>
          <Inner>
          <div style={{ height:'100vh', display:'flex', flexWrap:'wrap', alignItems:'center', justifyContent:'center'}}>
            <img style={{ width:'400px'}} src={Rocket} alt="React Logo" />
            <div style={{ width:'50%'}}>
                <h1 style={{fontSize:'60px'}}>Progres.software</h1>
                <h4 style={{fontSize:'22px'}}>
                Software development and consultancy services for the next generation of companies
                </h4>
            </div>
          </div>
          </Inner>
        </Content>
      </div>    )
}

export default Hero