import React from "react"
import { jsx } from "theme-ui"
import { ParallaxLayer } from "react-spring/renderprops-addons.cjs"



const Content = ({ speed, offset, children, className = ``, factor = 1 }) => (
  <ParallaxLayer
    style={{ zIndex:'10', padding:'3 4 4 5', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}
    speed={speed}
    offset={offset}
    factor={factor}
    className={className}
  >
    {children}
  </ParallaxLayer>
)

export default Content