import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`About`}</h1>
    <h5>
Progres.software is a software and business solution provider located in Taipei, Taiwan. 
Established in 2020 to steer clients through the next generation of innovation powered by technology with state-of-the-art business automation, software development and consultancy services. 
We specialized in the network monitoring market providing automation and solutions products in the field.
    </h5>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      